<template>
  <div class="header_box" id="HomePageTopCon" :style="`background-color: rgba(255, 255, 255, ${schedule / 100})`"
    :class="{ slide: schedule == 100 }">
    <div class="head_main">
      <img class="logo" src="../../../assets/jingtaiye-pic/lecturePromotion/logo.svg" alt=""
        @click="$router.replace('/')" />

      <div class="btn_box">
        <el-button @click="toLogin">
          Login
        </el-button>
        <el-button @click="toRegister">
          Apply now
        </el-button>
      </div>


    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      schedule: 50,
      goodsNum: '',
      dialogVisible: true,

    }
  },
  components: {

  },
  computed: {
  },
  created() {
    console.log(this.schedule, 'scheduled');

  },
  mounted() {
    document.addEventListener("scroll", this.throttle(this.scrollPage, 10));

  },
  unmounted() {
    document.removeEventListener("scroll", this.scrollPage)
  },
  methods: {
    toLogin() {
      window.open('https://promote.rakumart.us/#/training/login', '_blank')
    },
    //注册讲师
    toRegister() {
      window.open('https://promote.rakumart.us/#/training/promote/register1?token=eyJsYW5ndWFnZSI6ImVzIiwidXNlcl9pZCI6MTAxNywicHJvbW90ZV90ZWwiOiIwNDMtMTIzNC01Njc4IiwicHJvbW90ZV9lbWFpbCI6InNlcnZpY2VAcmFrdW1hcnQuY28ua3IiLCJwcm9tb3RlX25hbWUiOiJcdTZiMjdcdTZkMzJcdTdiYTFcdTc0MDZcdTdhZWYifQ%3D%3D', '_blank')
    },
    throttle(fn, delay) {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        if (!timer) {
          timer = setTimeout(function () {
            fn.apply(context, args);
            timer = null;
          }, delay);
        }
      }
    },
    scrollPage(e) {
      let osTop = document.documentElement.scrollTop || document.body.scrollTop;
      let Max = 800;
      let min = 0;
      let kedu = (Max - min) / 100;
      let jindu = Math.max(Math.min(osTop / kedu, 100), 50); //滚动进度
      this.schedule = jindu;
    },
    avatarbefoUpload(file) {
      const isJPG = file.type.indexOf('image') !== -1
      if (!isJPG) {
        this.$message.error(this.$fanyi('请上传图片'))
        return isJPG
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi('图片上传中'),
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, .7)',
      })
    },
    // 图片上传成功事件
    handleAvatarSuccess(res) {
      this.$api.userPortraitSave({ head_img: res.data }).then((res) => {
        if (res.code != 0) return
        this.$api.EuropegetUserInfo().then((res) => {
          this.$store.commit('userData', res.data)
          this.loading.close()
          this.$forceUpdate()
        })
      })
    },
    // 退出登录
    loginOut() {
      localStorage.removeItem("user_token");
      this.$store.commit("userData", null);
      this.$store.commit("setcartCount", 0);
      this.$store.commit("Sidebardatacleanup");
      localStorage.removeItem("clientUsFrontPCkeyWord");
      let requireAuth = this.$route.matched[0].meta.requireAuth;
      if (requireAuth) {
        this.$fun.toPage("/");
      }
    },
    clickDropdown(value) {
      if (value != "") {
        this.$store.commit("Togglelanguagetype", value);
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.slide {
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
}

.header_box {
  height: 100px;
  position: sticky;
  top: 0;
  z-index: 100;

  .head_main {
    height: 100%;
    width: 73vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    .logo {
      width: 198px;
      height: 36px;
      cursor: pointer;
    }

    .btn_box {
      .el-button {
        width: 7.3vw;
        height: 2.8vw;
        border-radius: 0.5vw;
        border: 0.1vw solid #FF730B;
        font-weight: 600;
        font-size: 1vw;
        color: #FF730B;
        box-sizing: border-box;
        background-color: transparent;
      }

      .el-button:nth-child(2) {
        background: #FF730B;
        color: #FFFFFF;
        font-style: normal;
        border: none;
      }
    }



  }
}
</style>